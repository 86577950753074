.container-item::-webkit-scrollbar {
  display: none;
}

.input-required {
  color: #e82e37;
  font-size: 1rem;
}

.scroll-container {
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
  height: 150px;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
}

.scroll-container-timer {
  height: 80px;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.steps {
  font-family: "Staatliches", sans-serif;
  margin-top: 15px;
  margin-left: 8px;
  margin-bottom: 4px;
  font-weight: 500;
  color: #e82e37;
}

.required {
  display: none;
  font-size: 0.8rem;
  margin-left: 5px;
  background: #fff;
  border-radius: 2px;
  padding: 2px;
  padding-right: 3px;
}

.required-show {
  display: inline-block;
  animation: slideInRight 0.2s ease-in backwards;
}

@keyframes slideInRight {
  0% {
    transform: translateX(800%);
  }
  100% {
    transform: translateX(0%);
  }
}

.checkout-container {
  display: flex;
  flex-direction: row;
}

.sneak-mode {
  margin: 4px 0 0;
  position: relative;
  display: inline-block;
  visibility: hidden;
  text-align: center;
  font-family: "Staatliches", sans-serif;
  color: #36ee70;
  font-weight: 400;
  &--active {
    visibility: unset;
  }
}

.sneak-mode--active::before { 
  display: inline-block;
    content: '';
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;
    background-color: #36ee70;
}
