.heading {
  font-size: 1.5rem;
  align-items: center;
  align-content: center;
  align-self: center;
  font-family: "Staatliches", sans-serif;
  color: #ffffff;
}

.text {
  margin-top: 0;
  padding-bottom: 10px;
  max-width: 80%;
  align-items: center;
  align-content: center;
  align-self: center;
  font-family: "Staatliches", sans-serif;
  font-size: 1.2rem;
  letter-spacing: 1.1px;
  color: #e82e37;
  &__about {
    height: 200px;
    padding-top: 40px;
  }
}

.sneak {
  margin-top: 5px;
  font-size: .9rem;
  color: #36ee70;
}
