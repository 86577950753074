@import url('https://fonts.googleapis.com/css2?family=Saira:wght@600;800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico|Quicksand:400,500,700|Staatliches&display=swap');

body {
  font-family: sans-serif, Tahoma, Geneva, Verdana;
  margin: 0px;
}

*{
  box-sizing: border-box;
}

*:before,
*:after {
box-sizing: border-box;
}

html,
body {
  height: 100%;
  position: relative;
}

.heading-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.txt-heading {
  margin-bottom: 0;
  align-self: center;
  font-family: 'Saira', sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 2rem;
  color: #ffffff;
  text-transform: uppercase;
  transition: 0.2s all ease-in;
}

.btn-checkout-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 500px) {
  .txt-heading {
    font-size: 1.5rem;
    margin-left: 35px;
  }
}
