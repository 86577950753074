.container-fluid {
  background: #f4f6f8;
  height: 100% !important;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-main {
  display: flex;
  flex-direction: column;
  width: 550px;
  box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.4);
  border-radius: 4px;
  background: #333;
  width: 90%;
  max-width: 575px;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .container-main {
    width: 100%;
    border-radius: unset;
  }
  .container-fluid {
    align-items: unset;
  }
}