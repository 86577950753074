.sneak {
  position: absolute;
  right: 0;
  align-self: center;
  margin: 12px 8px 0 12px;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: 'Pacifico', cursive;
  letter-spacing: 1px;
  transition: 0.2s all ease-in;
}
