@import url(https://fonts.googleapis.com/css2?family=Saira:wght@600;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Pacifico|Quicksand:400,500,700|Staatliches&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: sans-serif, Tahoma, Geneva, Verdana;
  margin: 0px;
}

*{
  box-sizing: border-box;
}

*:before,
*:after {
box-sizing: border-box;
}

html,
body {
  height: 100%;
  position: relative;
}

.heading-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.txt-heading {
  margin-bottom: 0;
  align-self: center;
  font-family: 'Saira', sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 2rem;
  color: #ffffff;
  text-transform: uppercase;
  transition: 0.2s all ease-in;
}

.btn-checkout-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 500px) {
  .txt-heading {
    font-size: 1.5rem;
    margin-left: 35px;
  }
}

.Home_container-item__2a7Ji::-webkit-scrollbar{display:none}.Home_input-required__peqKv{color:#e82e37;font-size:1rem}.Home_scroll-container__36FAP{display:flex;margin-bottom:10px;flex-direction:row;overflow-x:scroll;width:100%;height:150px;flex-wrap:nowrap;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar;scrollbar-width:none}.Home_scroll-container-timer__Kv1nw{height:80px}.Home_scroll-container__36FAP::-webkit-scrollbar{display:none}.Home_steps__2nxdP{font-family:"Staatliches", sans-serif;margin-top:15px;margin-left:8px;margin-bottom:4px;font-weight:500;color:#e82e37}.Home_required__xbZms{display:none;font-size:0.8rem;margin-left:5px;background:#fff;border-radius:2px;padding:2px;padding-right:3px}.Home_required-show__Jjjia{display:inline-block;-webkit-animation:Home_slideInRight__3q42p 0.2s ease-in backwards;animation:Home_slideInRight__3q42p 0.2s ease-in backwards}@-webkit-keyframes Home_slideInRight__3q42p{0%{-webkit-transform:translateX(800%);transform:translateX(800%)}100%{-webkit-transform:translateX(0%);transform:translateX(0%)}}@keyframes Home_slideInRight__3q42p{0%{-webkit-transform:translateX(800%);transform:translateX(800%)}100%{-webkit-transform:translateX(0%);transform:translateX(0%)}}.Home_checkout-container__PunVU{display:flex;flex-direction:row}.Home_sneak-mode__2oKC6{margin:4px 0 0;position:relative;display:inline-block;visibility:hidden;text-align:center;font-family:"Staatliches", sans-serif;color:#36ee70;font-weight:400}.Home_sneak-mode--active__1DIE-{visibility:unset}.Home_sneak-mode--active__1DIE-::before{display:inline-block;content:'';border-radius:0.375rem;height:0.75rem;width:0.75rem;margin-right:0.5rem;background-color:#36ee70}

.Layout_container-fluid__8OYjr{background:#f4f6f8;height:100% !important;min-height:100vh;display:flex;justify-content:center;align-items:center}.Layout_container-main__2VF2S{display:flex;flex-direction:column;width:550px;box-shadow:0px 0px 8px 2px rgba(0,0,0,0.4);border-radius:4px;background:#333;width:90%;max-width:575px;position:relative}@media only screen and (max-width: 575px){.Layout_container-main__2VF2S{width:100%;border-radius:unset}.Layout_container-fluid__8OYjr{align-items:unset}}

.Button_button__2Lf63{margin:20px auto;padding:0.8em 1.4em;border:2px solid #e82e37;border-radius:2px;text-decoration:none;transition:0.2s all ease-in;text-transform:uppercase;outline:none;cursor:pointer;background:none;font-family:"Saira", sans-serif;font-weight:600;font-size:1rem;color:#e82e37}.Button_button-primary__1rm1L{align-self:center}.Button_button-continue__1K9Wu{background:none !important}.Button_button-continue__1K9Wu:hover{background:#601a1d !important}.Button_button-enabled__2asMO{background:#e82e37;color:#ffffff}.Button_button-enabled__2asMO:hover{background:#601a1d}

.item {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 150px;
  background: #492a2a; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cedce7 0%,
    #492a2a 78%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cedce7', endColorstr='#492a2a',GradientType=0 ); /* IE6-9 */

  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  flex: 0 0 auto;
  border-radius: 5px;
  color: #333333;
}

.item-clicked {
  background: #ffffff;
}

.item-timer {
  width: 150px;
  height: 80px;
}

.item-title {
  padding: 4px 8px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
}

.item-title-clicked {
  color: #e82e37;
}

.item-selected-txt {
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
}

.Logo_logo__3IG6m{position:absolute;left:0;align-self:center;margin:10px 10px 0 12px;background:#6c3737;padding-left:8px;padding-right:8px;padding-bottom:2px;border-radius:5px;cursor:pointer;font-size:1.2rem;font-family:'Pacifico', cursive;letter-spacing:1px;color:#e82e37;transition:0.2s all ease-in}.Logo_logo__3IG6m:hover{background:#7a4343}@media only screen and (max-width: 500px){.Logo_logo__3IG6m{font-size:.9rem}}

footer{bottom:0;width:100%;padding:20px;display:flex;flex:1 1;flex-direction:column;justify-content:flex-end;align-items:center}.Footer_image__logo--footer__p3GB1{width:125px}.Footer_ftr-copyright__2nI3q{font-family:"Staatliches", sans-serif;font-size:0.8rem;border-top:1px solid #e82e37;padding-top:5px;color:#e82e37}.Footer_icon__2fpab{position:absolute;bottom:0;margin:14px;padding:5px;height:35px;border:1px solid #e82e37;background:none;border-radius:2px;cursor:pointer;transition:0.2s all ease-in;font-family:"Staatliches", sans-serif;font-size:1rem;color:#e82e37}.Footer_icon__2fpab:hover{background:#601a1d}.Footer_info__2h2NT{left:0}.Footer_email__3-Iet{right:0}

.Share_share-txt-container__wH_jR{text-align:center}.Share_share-icon-container__1MmMB{display:flex;justify-content:center;margin-bottom:20px}.Share_share-icon-btn__3e4uR{margin-left:6px;margin-right:6px;outline:none}.Share_share-txt-title__3Ug0a{display:inline-block;margin-right:5px}.Share_btn-share__Bw7Xc{margin:0 auto;padding:0 10px;padding-right:1px;outline:0;cursor:pointer;font-size:.9rem;letter-spacing:1.1px;transition:all 0.2s linear;border:1px solid #000000;margin-right:20px;margin-bottom:10px;height:40px}.Share_btn-share__Bw7Xc:hover{background-color:#f7f4f4}.Share_heading__EC1EB{margin-bottom:8px;padding-top:5px;font-family:'Staatliches', sans-serif;font-size:1rem;color:#e82e37}

.SneakMode_sneak__2E0D-{position:absolute;right:0;align-self:center;margin:12px 8px 0 12px;padding:2px;border-radius:5px;cursor:pointer;font-size:1.2rem;font-family:'Pacifico', cursive;letter-spacing:1px;transition:0.2s all ease-in}

.About_heading__2N5u-{font-size:1.5rem;align-items:center;align-content:center;align-self:center;font-family:"Staatliches", sans-serif;color:#ffffff}.About_text__U-UOu{margin-top:0;padding-bottom:10px;max-width:80%;align-items:center;align-content:center;align-self:center;font-family:"Staatliches", sans-serif;font-size:1.2rem;letter-spacing:1.1px;color:#e82e37}.About_text__about__1hYHm{height:200px;padding-top:40px}.About_sneak__2QFUg{margin-top:5px;font-size:.9rem;color:#36ee70}

.ProcessFart_item__31WgN{margin:40px auto;margin-bottom:10px;align-self:center}.ProcessFart_item-process__1b9WZ{-webkit-animation-name:ProcessFart_spin__oZaX2;animation-name:ProcessFart_spin__oZaX2;-webkit-animation-duration:1000ms;animation-duration:1000ms;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear}@-webkit-keyframes ProcessFart_spin__oZaX2{0%{-webkit-transform:rotate(0deg) scale(1);transform:rotate(0deg) scale(1)}50%{-webkit-transform:scale(1.2);transform:scale(1.2)}100%{-webkit-transform:rotate(360deg) scale(1);transform:rotate(360deg) scale(1)}}@keyframes ProcessFart_spin__oZaX2{0%{-webkit-transform:rotate(0deg) scale(1);transform:rotate(0deg) scale(1)}50%{-webkit-transform:scale(1.2);transform:scale(1.2)}100%{-webkit-transform:rotate(360deg) scale(1);transform:rotate(360deg) scale(1)}}

.TimerDisplay_container__1eu-D{display:flex;flex-direction:row;justify-content:center;margin:0 auto;margin-bottom:10px}.TimerDisplay_time__YIHKc{font-family:"Staatliches", sans-serif;font-size:2rem;border-top:1px solid #e82e37;padding-top:5px;color:#e82e37}

