.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 10px;
}

.time {
  font-family: "Staatliches", sans-serif;
  font-size: 2rem;
  border-top: 1px solid #e82e37;
  padding-top: 5px;
  color: #e82e37;
}
