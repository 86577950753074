.logo {
  position: absolute;
  left: 0;
  align-self: center;
  margin: 10px 10px 0 12px;
  background: #6c3737;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: 'Pacifico', cursive;
  letter-spacing: 1px;
  color: #e82e37;
  transition: 0.2s all ease-in;
  &:hover {
    background: #7a4343;
  }
}

@media only screen and (max-width: 500px) {
  .logo {
    font-size: .9rem;
  }
}
