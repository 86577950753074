.item {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 150px;
  background: #492a2a; /* Old browsers */
  background: -moz-linear-gradient(top, #cedce7 0%, #492a2a 78%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #cedce7 0%,
    #492a2a 78%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cedce7 0%,
    #492a2a 78%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cedce7', endColorstr='#492a2a',GradientType=0 ); /* IE6-9 */

  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  flex: 0 0 auto;
  border-radius: 5px;
  color: #333333;
}

.item-clicked {
  background: #ffffff;
}

.item-timer {
  width: 150px;
  height: 80px;
}

.item-title {
  padding: 4px 8px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
}

.item-title-clicked {
  color: #e82e37;
}

.item-selected-txt {
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
}
