footer {
  bottom: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.image__logo--footer {
  width: 125px;
}

.ftr-copyright {
  font-family: "Staatliches", sans-serif;
  font-size: 0.8rem;
  border-top: 1px solid #e82e37;
  padding-top: 5px;
  color: #e82e37;
}

.icon {
  position: absolute;
  bottom: 0;
  margin: 14px;
  padding: 5px;
  height: 35px;
  border: 1px solid #e82e37;
  background: none;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.2s all ease-in;
  font-family: "Staatliches", sans-serif;
  font-size: 1rem;
  color: #e82e37;
  &:hover {
    background: #601a1d;
  }
}
.info {
  left: 0;
}

.email {
  right: 0;
}
